<template>
  <auth-layout ref="authLayout" id="authLayout">
    <div class="uk-card-default p-5 rounded">
      <div class="mb-4">
        <p class="my-2" style="font-size:1rem;">{{ $t('login.confirmation_sms_text') }}</p>
        <b>
          <p style="font-size:.875rem;"><span id="time">02:00</span> - <a href="#" @click="sendConfirmationCode()">
              {{ $t('general.send_again') }}</a>
          </p>
        </b>
      </div>
      <form @submit.prevent="onSubmit()">
        <div class="uk-form-group">
          <label class="uk-form-label">{{ $t('login.confirmation_code') }}</label>
          <div class="uk-position-relative w-100">
            <span class="uk-form-icon">
              <i class="icon-feather-align-left"></i>
            </span>
            <input class="uk-input" type="text" v-model="code" maxlength="5">
          </div>
          <div class="uk-alert-danger" uk-alert v-if="errors || errorMessage">
            <a class="uk-alert-close" uk-close></a>
            <p style="color:red;">
              <i class="uil-exclamation-triangle mr-2"></i>{{ errors ? $t(errors) : $t(errorMessage) }}
            </p>
          </div>

          <div :class="has_error ? 'uk-alert-danger' : 'uk-alert-success'" uk-alert v-if="message">
            <a class="uk-alert-close" uk-close></a>
            <p :style="has_error ? 'color:red;' : 'color:green;'"><i class="uil-exclamation-triangle"></i> {{ message }}
            </p>
          </div>
        </div>
        <div class="mt-4 uk-flex-middle uk-grid-small" uk-grid>
          <div class="uk-width-expand@s">
            <button type="button" class="btn" @click="logout()"><i class="uil-times"></i>{{ $t('general.logout')
              }}</button>
          </div>
          <div class="uk-width-auto@s">
            <button type="submit" class="btn btn-default" :disabled="is_loading">
              <span uk-spinner="ratio : .4" class="mr-2d" v-if="is_loading"></span>{{ $t('general.ok') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </auth-layout>
</template>

<script>
import AuthLayout from "@/view/layouts/auth/AuthLayout";
import AuthLayoutLogin from "@/view/auth/Login";

import { mapState } from "vuex";
import { LOGIN, LOGOUT, SEND_SMS, SET_ERROR } from "@/core/services/store/auth.module";
import { ref, onMounted } from "vue";
import {DomainMapper} from "@/core/config/domain-mapper";
export default {
  name: "ConfirmAccessCode",
  components: {
    AuthLayout
  },

  data() {
    return {
      errorMessage: '',
      message: null,
      is_loading: false,
      code: '',
      has_error: false,
      countMin: 60 * 2,
      display: null,
      intervalId: null,
      parentData: this.$parent
    }
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
  },
  methods: {
    logout() {
      this.$store.dispatch(LOGOUT);
      this.$router.push({ path: '/login' })
    },
    sendConfirmationCode() {
      let username = this.user.username
      let password = this.user.password

      let alias = process.env.VUE_APP_DEFAULT_SUBDOMAIN;

      if (['production', 'staging'].includes(process.env.NODE_ENV)) {
        alias = window.location.hostname.split(".")[0];
        if (["production"].includes(process.env.NODE_ENV) && DomainMapper.hasOwnProperty(window.location.hostname)){
          alias = DomainMapper[window.location.hostname] || alias;
        }
      }

      this.display.textContent = "02:00";
      this.countMin = 60 * 2
      clearInterval(this.intervalId)
      this.startTimer()
      // send login request
      this.$store.dispatch(LOGIN, { username, password, alias })
        .then((response) => {
          this.message = this.$t('messages.send_sms_successful')
        }).catch((error) => {
        })
    },
    onSubmit() {
      let username = this.user.username
      let password = this.user.password
      let code = this.code
      let login_field = this.$route.params.user.login_field
      this.message = null
      this.has_error = false
      if (code.length !== 5) {
        this.has_error = true
        this.message = this.$t("validation.required_field")
          .replace(":NAME", this.$t('login.confirmation_code'))
        return false;
      }
      let alias = process.env.VUE_APP_DEFAULT_SUBDOMAIN;
      if (['production', 'staging'].includes(process.env.NODE_ENV)) {
        alias = window.location.hostname.split(".")[0];
        if (["production"].includes(process.env.NODE_ENV) && DomainMapper.hasOwnProperty(window.location.hostname)){
          alias = DomainMapper[window.location.hostname] || alias;
        }
      }

      if (!username || !password || !code) {
        this.$router.push({ path: '/login' })
      } else {
        // send login request
        let redirectUrl = window.localStorage.getItem("redirect_url");
        // let classRoomId = window.localStorage.getItem('classRoomId');
        let classRoomId = null
        if (redirectUrl?.includes("/classroom-education")) {

          let test = redirectUrl.replace("/classroom-education", "");
          classRoomId = test.substring(test.lastIndexOf('/') + 1);
        }
        if (classRoomId) {
          this.$store.dispatch(SEND_SMS, { username, password, code, alias })
            .then((response) => {
              this.$router.push({ path: "/assignments/" + classRoomId + "/classroom-education" })
            })

        } else
          if (redirectUrl && typeof redirectUrl !== "undefined") {

            this.$store.dispatch(SEND_SMS, { username, password, code, alias, login_field })
              .then((response) => {
                this.$router.push({ path: redirectUrl })
              }).catch((error) => {
              })
          } else {
            this.$store.dispatch(SEND_SMS, { username, password, code, alias, login_field })
              .then((response) => {
                this.$router.push({ path: "/dashboard" })
                window.localStorage.setItem("loginMethod", 'otp');
              })
          }
        /* this.$store.dispatch(SEND_SMS, {username, password, code, alias }\|)
            .then((response) => {
              this.$router.push({ path: "/dashboard"})
            }) */
      }
    },
    startTimer() {
      let timer = this.countMin,
        minutes, seconds;
      let display = this.display;
      this.intervalId = setInterval(function () {
        minutes = parseInt(timer / 60, 10)
        seconds = parseInt(timer % 60, 10);
        minutes = minutes < 10 ? "0" + minutes : minutes;
        seconds = seconds < 10 ? "0" + seconds : seconds;
        display.textContent = minutes + ":" + seconds;

        if (--timer < 0) {
          timer = 0, 0, 0;
          $('.sms-code').addClass('send-sms-code')
        }
      }, 1000);
    }
  },
  created() {
    this.user = this.$route.params.user;
    if (!this.user) {
      this.$router.push({ path: '/login' })
    }
  },

  mounted() {
    this.$store.commit(SET_ERROR, '')
    this.display = document.querySelector('#time');
    this.startTimer();
  },
  watch: {
    errors(val) {
      if (val)
        this.is_loading = false
    }
  }
}
</script>

<style scoped></style>